import React, { useState, useEffect, useCallback } from "react";
import { Form, Formik } from "formik";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useFetch from "../../../shared/hooks/useFetch";
import { useNavigate } from "react-router-dom";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	PlusIcon,
} from "@heroicons/react/16/solid";
import Button from "../../../shared/components/FormElements/Button";
import PatientBasicInfo from "./PatientBasicInfo";
import PatientAdvancedInfo from "./PatientAdvancedInfo";
import { validationSchema, validationSchemaStepOne } from "./validationSchema";
import { handleSubmit } from "./submitHandler";
import { demoPatient } from "./demoPatient";
import PrefilledFromDesktopInfo from "./PrefilledFromDesktopInfo";
import DesktopAppNotice from "../DesktopAppNotice";
import AnimateIn from "../../../shared/components/Animations/AnimateIn";

const NewPatientForm = ({
	reload,
	setOpen,
	isEditing = false,
	initialPatient,
	initialValues = { phoneNumber: "+31 6" },
	fromDesktopApp = false,
	fromWizard = false,
	setCurrentStep,
}) => {
	const { user } = useUser();
	const navigate = useNavigate();
	const createPatient = useFetch();

	const [step, setStep] = useState(1);
	const [treatments, setTreatments] = useState([]);
	const [behandelaarTreatments, setBehandelaarTreatments] = useState([]);
	const [availableTreatments, setAvailableTreatments] = useState([]);

	const [prefilledValues, setPrefilledValues] = useState({});
	const [isLoadingIntegrationResult, setIsLoadingIntegrationResult] =
		useState(true);
	const [foundPhoneNumbers, setFoundPhoneNumbers] = useState([]);

	const [currentValidationSchema, setCurrentValidationSchema] = useState(
		validationSchemaStepOne,
	);

	useEffect(() => {
		const fetchTreatments = async () => {
			try {
				const response = await createPatient("/api/treatments");
				const formattedResponse = response.map((treatment) => ({
					label: treatment.name,
					value: treatment._id,
					possibleDurations: treatment.possibleDurations,
				}));
				setTreatments(formattedResponse);
			} catch (error) {
				console.error("Error fetching treatments:", error);
				toast.error("Fout bij het ophalen van de behandelingen", {
					position: "bottom-center",
				});
			}
		};

		fetchTreatments();
	}, []);

	useEffect(() => {
		const filteredTreatments = treatments.filter((treatment) =>
			behandelaarTreatments.includes(treatment.value),
		);
		setAvailableTreatments(filteredTreatments);
	}, [behandelaarTreatments, treatments]);

	useEffect(() => {
		if (fromDesktopApp) {
			setStep(2);
		}
	}, [fromDesktopApp]);

	useEffect(() => {
		setCurrentValidationSchema(
			step === 1 ? validationSchemaStepOne : validationSchema,
		);
	}, [step]);

	return (
		<Formik
			initialValues={
				fromDesktopApp
					? prefilledValues
					: process.env.REACT_APP_STAGING === "true" &&
						  !isEditing &&
						  !fromDesktopApp
						? demoPatient
						: isEditing
							? initialPatient
							: initialValues
			}
			enableReinitialize
			validationSchema={currentValidationSchema}
			onSubmit={(values, actions) =>
				handleSubmit(
					fromDesktopApp,
					values,
					actions,
					step,
					setStep,
					fromWizard,
					setCurrentStep,
					isEditing,
					initialPatient,
					user,
					createPatient,
					navigate,
					setOpen,
				)
			}
		>
			{({ values, isSubmitting, setFieldValue, errors, touched }) => (
				<>
					<div className="flex gap-x-5">
						<Form autoComplete="off">
							<div className="flex flex-wrap content-start">
								{step === 1 && (
									<AnimateIn>
										<div className="flex flex-col gap-y-5">
											<div className="bg-white rounded-2xl shadow p-10 pt-12 max-w-xl border border-slate-200 mb-5">
												<PatientBasicInfo
													values={values}
													setFieldValue={
														setFieldValue
													}
												/>
											</div>
											{!fromDesktopApp && (
												<div className="max-w-xl">
													<DesktopAppNotice />
												</div>
											)}
										</div>
									</AnimateIn>
								)}
								{step === 2 && (
									<AnimateIn>
										<div className="bg-white rounded-2xl shadow p-10 pt-12 border border-slate-200 min-w-[600px] mb-12">
											<PatientAdvancedInfo
												values={values}
												setFieldValue={setFieldValue}
												fromDesktopApp={fromDesktopApp}
												availableTreatments={
													availableTreatments
												}
												treatments={treatments}
												setBehandelaarTreatments={
													setBehandelaarTreatments
												}
												isLoadingIntegrationResult={
													isLoadingIntegrationResult
												}
												foundPhoneNumbers={
													foundPhoneNumbers
												}
											/>
										</div>
									</AnimateIn>
								)}
							</div>

							<div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 shadow-2xl">
								<div className="max-w-7xl mx-auto px-4 sm:px-6 flex gap-x-2">
									{step === 2 && (
										<Button
											ghost
											iconPrefix={ArrowLeftIcon}
											type="button"
											onClick={() => {
												setStep(1);
												if (fromWizard)
													setCurrentStep(0);
											}}
										>
											Terug
										</Button>
									)}
									{step === 1 && (
										<Button
											onClick={() => {
												const isStepOneValid =
													Object.keys(
														validationSchemaStepOne.fields,
													).every(
														(field) =>
															!errors[field] ||
															!touched[field],
													);
												if (isStepOneValid) {
													setStep(2);
													if (fromWizard)
														setCurrentStep(1);
												} else {
													toast.error(
														"Vul alle verplichte velden correct in voordat je doorgaat.",
														{
															position:
																"bottom-center",
														},
													);
												}
											}}
											disabled={isSubmitting}
											iconSuffix={ArrowRightIcon}
										>
											Volgende
										</Button>
									)}
									{step === 2 && (
										<Button
											type="submit"
											disabled={isSubmitting}
											iconSuffix={PlusIcon}
										>
											{isEditing
												? "Patiënt updaten"
												: "Patiënt toevoegen"}
										</Button>
									)}
								</div>
							</div>
						</Form>
						{fromDesktopApp && (
							<PrefilledFromDesktopInfo
								setStep={setStep}
								setPrefilledValues={setPrefilledValues}
								setIsLoadingIntegrationResult={
									setIsLoadingIntegrationResult
								}
								setFoundPhoneNumbers={setFoundPhoneNumbers}
								isVisible={step === 2}
							/>
						)}
					</div>
				</>
			)}
		</Formik>
	);
};

export default NewPatientForm;
