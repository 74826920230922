import React from "react";
import {
	CheckIcon,
	EllipsisHorizontalIcon,
	ExclamationTriangleIcon,
	XMarkIcon,
	ChatBubbleOvalLeftIcon,
} from "@heroicons/react/16/solid";

import classNames from "classnames";
import Tooltip from "../../../shared/components/UIElements/Tooltip";

const BatchTimelineItem = ({
	patient,
	slotId,
	slotDate,
	sent,
	claimedBy,
	onSelectPatient,
	batch,
}) => {
	const relevantMessage =
		sent &&
		patient.patient.messages.find((message) => message.slotId === slotId);

	const patientDidClaim = sent && claimedBy._id === patient.patient._id;
	const patientDidRead =
		relevantMessage && relevantMessage.deliveryStatus === "read";

	return (
		<li
			className={classNames(
				"px-6 py-3 border w-fit rounded-lg shadow flex items-center",
				{
					"border-blue-500 border-[1.5px] text-blue-900":
						patientDidRead &&
						relevantMessage?.response !== "accepted" &&
						relevantMessage?.response !== "rejected",
					"border-brand-500":
						relevantMessage?.response !== "rejected" &&
						!patientDidRead,
					"border-gray-300": relevantMessage?.response === "rejected",
					"border-green-500 border-2": patientDidClaim,
					// "border-gray-300 bg-gray-25 opacity-50":
					// 	sent && !patient.dateSent,
					"cursor-pointer hover:scale-105": sent,
					"border-red-400 bg-red-50 bg-opacity-20 text-red-900":
						relevantMessage?.channel !== "sms" &&
						patient.patient.errors.count > 0 &&
						patient.patient.errors.erroredAt[
							patient.patient.errors.erroredAt.length - 1
						] > slotDate,
					"opacity-50": sent && !batch.dateSent,
				},
			)}
			onClick={() => onSelectPatient(patient.patient._id)}
		>
			{patient.patient.fullName
				? patient.patient.fullName
				: `${patient.patient.firstName} ${patient.patient.lastName}`}
			{patient.patient.status === "deleted" && (
				<span className="italic">Verwijderde patiënt</span>
			)}
			{relevantMessage?.response === "rejected" && (
				<div className="ml-2">
					<Tooltip
						content="Heeft aangegeven niet te kunnen"
						id={`afspraak-geweigerd-${patient.patient._id}`}
					>
						<div className="bg-red-100 rounded-full p-1">
							<XMarkIcon className="h-4 w-4 text-red-500" />
						</div>
					</Tooltip>
				</div>
			)}

			{patientDidRead &&
				relevantMessage?.response !== "accepted" &&
				relevantMessage?.response !== "rejected" && (
					<div className="ml-1">
						<Tooltip
							content="Uitnodiging gelezen"
							id={`read-${patient.patient._id}`}
						>
							<div className=" rounded-full flex items-center justify-center">
								<CheckIcon className="size-4 text-blue-500" />
								<CheckIcon className="size-4 text-blue-500 -ml-[11px]" />
							</div>
						</Tooltip>
					</div>
				)}
			{relevantMessage?.response === "accepted" &&
				patient.patient._id !== claimedBy._id && (
					<div className="ml-2">
						<Tooltip
							content={
								claimedBy._id
									? "Heeft geprobeerd de afspraak te bevestigen, maar de plek was al gevuld. De patiënt is hiervan op de hoogte gebracht."
									: "Heeft op 'Ja' geklikt, maar heeft de afspraak nog niet bevestigd. Over een paar minuten nog steeds niks binnen? Bel de patiënt."
							}
							id={`afspraak-nog-niet-bevestigd-${patient.patient._id}`}
						>
							<div className="bg-orange-100 rounded-full p-1">
								<EllipsisHorizontalIcon className="h-4 w-4 text-orange-500" />
							</div>
						</Tooltip>
					</div>
				)}
			{patient.patient._id === claimedBy._id && (
				<div className="ml-2">
					<Tooltip
						content="Afspraak bevestigd!"
						id={`afspraak-bevestigd-${patient.patient._id}`}
					>
						<div className="bg-green-100 rounded-full p-1">
							<CheckIcon className="h-4 w-4 text-green-500" />
						</div>
					</Tooltip>
				</div>
			)}

			{relevantMessage?.channel === "sms" && (
				<div className="ml-2">
					<Tooltip
						content="Uitnodiging afgeleverd via SMS"
						id={`afspraak-sms-${patient.patient._id}`}
					>
						<ChatBubbleOvalLeftIcon className="h-4 w-4 text-brand-500" />
					</Tooltip>
				</div>
			)}

			{relevantMessage?.channel !== "sms" &&
				patient.patient.errors.count > 0 &&
				patient.patient.errors.erroredAt[
					patient.patient.errors.erroredAt.length - 1
				] > slotDate &&
				patient.status !== "rejected" &&
				patient.status !== "accepted" && (
					<div className="ml-2">
						<Tooltip
							content="Bericht niet afgeleverd"
							id={`afspraak-foutmelding-${patient.patient._id}`}
						>
							<ExclamationTriangleIcon className="h-4 w-4 text-red-600" />
						</Tooltip>
					</div>
				)}
		</li>
	);
};

export default BatchTimelineItem;
