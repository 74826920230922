import React, { useEffect, useState } from "react";

import Container from "../../shared/components/UIElements/Container";
import Heading from "../../shared/components/UIElements/Heading";
import NewSlotForm from "../components/creation-wizard/NewSlotForm";
import SlotSteps from "../components/creation-wizard/SlotSteps";
import NewSlotBatches from "../components/creation-wizard/NewSlotBatches";
import NewSlotPatients from "../components/creation-wizard/NewSlotPatients";
import { Link } from "react-router-dom";
import MainLogo from "../../shared/components/Branding/MainLogo";
import { XMarkIcon } from "@heroicons/react/20/solid";

import backgroundImage from "../../assets/Background.png";
import CloseWizard from "../components/creation-wizard/CloseWizard";
import AnimateIn from "../../shared/components/Animations/AnimateIn";

const NewSlotPage = () => {
	const [slotDetails, setSlotDetails] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);
	const [slotID, setSlotID] = useState(null);
	const [formValues, setFormValues] = useState({
		date: "",
		startTime: "",
		endTime: "",
		behandelaar: "",
	});
	const [selectedPatients, setSelectedPatients] = useState([]);

	const steps = [
		{
			name: "Details",
			status: "complete",
		},
		{
			name: "Kies patiënten",
			status:
				currentStep === 0
					? "current"
					: currentStep > 0
						? "complete"
						: "upcoming",
		},
		{
			name: "Preview",
			status:
				currentStep === 1
					? "current"
					: currentStep > 1
						? "complete"
						: "upcoming",
		},
	];

	const backgroundImageUrl = `${process.env.REACT_APP_FRONTEND_URL}${backgroundImage}`;

	const handleSlotDetailsSubmit = (details) => {
		setSlotDetails(details);
		setFormValues(details);
		setSlotID(details.slotID);
		setCurrentStep(1);
		// Reset selected patients when slot details are changed
		setSelectedPatients([]);
	};

	const handleChosenPatients = (patients) => {
		setSelectedPatients(patients);
		setCurrentStep(2);
	};

	const handleBack = () => {
		setCurrentStep(currentStep - 1);
	};

	return (
		<div
			className="bg-slate-100 min-h-screen pb-20 flex flex-col"
			style={
				currentStep === 0 || currentStep === 2
					? {
							backgroundImage: `url(${backgroundImageUrl})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "contain",
							backgroundPosition: "right bottom",
						}
					: {}
			}
		>
			<div className="flex-grow">
				<div className="flex justify-between pt-14 pb-16 items-center max-w-7xl mx-auto px-4 sm:px-6">
					<div className="w-1/3 justify-start hidden md:flex">
						<MainLogo />
					</div>

					<div className="w-1/2 md:w-1/3 flex justify-center">
						<SlotSteps
							steps={steps}
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
						/>
					</div>

					<div className="w-1/3 flex justify-end">
						<CloseWizard currentStep={currentStep} to="/gaten/" />
					</div>
				</div>
				<div className="max-w-7xl mx-auto px-4 sm:px-6">
					<div>
						{currentStep === 0 && (
							<NewSlotForm
								onSlotDetailsSubmit={handleSlotDetailsSubmit}
								initialFormValues={formValues}
							/>
						)}
						{currentStep === 1 && (
							<NewSlotPatients
								onChosenPatients={handleChosenPatients}
								slotID={slotID}
								onBack={handleBack}
								selectedPatients={selectedPatients}
								setSelectedPatients={setSelectedPatients}
							/>
						)}
						{currentStep === 2 && (
							<NewSlotBatches
								slotDetails={slotDetails}
								slotID={slotID}
								onBack={handleBack}
								selectedPatients={selectedPatients}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewSlotPage;
