import React, { useState, useEffect } from "react";

import { useClerk } from "@clerk/clerk-react";
import useFetch from "../../../shared/hooks/useFetch";

import FormattedDate from "../../../shared/components/util/FormattedDate";
import warningImage from "../../../assets/Warning.png";

const PatientDeletedWarning = ({ patientId }) => {
	const [hasRequestedDeletion, setHasRequestedDeletion] = useState(false);
	const [dateOfDeletion, setDateOfDeletion] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [debouncedPatientId, setDebouncedPatientId] = useState(patientId);

	const fetchPatient = useFetch();
	const { user } = useClerk();

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedPatientId(patientId);
		}, 1000); // Adjust the delay as needed

		return () => {
			clearTimeout(handler);
		};
	}, [patientId]);

	useEffect(() => {
		async function fetchPatientDeletion() {
			const response = await fetchPatient(
				`/api/patients/user/${user.id}/${debouncedPatientId}/deleted`,
			);

			setHasRequestedDeletion(response.deleted);
			setDateOfDeletion(response.deletedAt);
		}

		if (debouncedPatientId) {
			fetchPatientDeletion();
		}
	}, [debouncedPatientId]);

	if (!hasRequestedDeletion) {
		return null;
	}

	return (
		<div className="p-4 bg-slate-50 border rounded-xl mt-4 mb-4">
			<div className="">
				<img src={warningImage} alt="Warning" className="size-6 mb-1" />
				<p className="text-sm text-slate-900 font-semibold">
					Patiënt heeft zichzelf van de Snelterecht lijst verwijderd
				</p>
			</div>
			<p className="text-sm text-slate-500 mt-2">
				<FormattedDate date={dateOfDeletion} alwaysDate /> heeft deze
				patiënt zichzelf verwijderd uit Snelterecht. Weet je zeker dat
				je hem opnieuw wilt toevoegen?
			</p>
		</div>
	);
};

export default PatientDeletedWarning;
