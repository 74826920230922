import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import useFetch from "../../shared/hooks/useFetch";

import NewPatientPage from "./NewPatientPage";

const EditPatientPage = ({ open, setOpen }) => {
	const [patient, setPatient] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const { patientId } = useParams();

	const fetchPatient = useFetch();

	useEffect(() => {

			const getPatient = async () => {
				const response = await fetchPatient(
					`/api/patients/${patientId}`,
				);
				setPatient({
					id: response._id,
					firstName: response.firstName,
					lastName: response.lastName,
					phoneNumber:
						response.phoneNumber || response.phoneNumbers[0],
					phoneNumber2: response.phoneNumbers[1],
					phoneNumber3: response.phoneNumbers[2],
					unformattedDate: response.appointment.datePlanned
						? dayjs(response.appointment.datePlanned)
						: null,
					otherBehandelaars: response.appointment.behandelaarType
						? true
						: false,
					treatment:
						response.appointment.treatmentType &&
						response.appointment.treatmentType._id,
					// datePlanned: response.appointment.datePlanned,
					notes: response.notes,
					priority: response.priority,
					unformattedInviteFrom: response.inviteFrom
						? dayjs(response.inviteFrom)
						: null,
					// inviteFrom: response.inviteFrom,
					behandelaar: response.behandelaar._id,
					patientnummer: response.patientNumber,
					behandelingDuur: +response.appointment.duration,
				});
				// window.confirm(JSON.stringify(patient));
				setIsLoading(false);
			};
			getPatient();

	}, []);

	if (!isLoading)
		return (
			<>

					<NewPatientPage isEditing={true} initialPatient={patient} />

				
</>
		);
};

export default EditPatientPage;
