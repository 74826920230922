import React from "react";

const SubHeading = ({ children, className }) => {
	return (
		<div className={`text-slate-500 uppercase font-semibold ${className}`}>
			{children}
		</div>
	);
};

export default SubHeading;
