import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useFormikContext } from "formik";
import useFetch from "../../../shared/hooks/useFetch";
import { toast } from "react-toastify";
import {
	PhoneIcon,
	PencilSquareIcon,
} from "@heroicons/react/16/solid";

import Tooltip from "../../../shared/components/UIElements/Tooltip";
import FormattedPhoneNumber from "../../../shared/components/util/FormattedPhoneNumber";
import SkeletonLoadingItem from "../../../shared/components/UIElements/SkeletonLoadingItem";

import ChoosePhoneNumber from "./ChoosePhoneNumber";

const hasPatientData = (patientData) => {
	return patientData && (patientData.firstName || patientData.lastName || patientData.patientnummer || (patientData.phoneNumbers && patientData.phoneNumbers.length > 0));
};

const PrefilledFromDesktopInfo = ({
	setStep,
	setPrefilledValues,
	setIsLoadingIntegrationResult,
	setFoundPhoneNumbers,
	isVisible,
}) => {
	const { values, setFieldValue } = useFormikContext();
	const location = useLocation();
	const createPatient = useFetch();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [initialData, setInitialData] = useState(null);

	const fetchIntegrationData = useCallback(async (documentId, key) => {
		try {
			const response = await createPatient(`/api/desktop/integration-results/${documentId}?key=${key}`);
			
			if (response.patientData && (response.patientData.firstName || response.patientData.lastName)) {
				const initialValues = {
					...response.patientData,
					phoneNumber: response.patientData.phoneNumbers.length === 1 ? response.patientData.phoneNumbers[0].tel : null,
				};

				setInitialData(initialValues);
				setPrefilledValues(initialValues);
				setPhoneNumbers(response.patientData.phoneNumbers);
				setFoundPhoneNumbers(response.patientData.phoneNumbers);

				// Set Formik field values
				Object.keys(initialValues).forEach(key => {
					setFieldValue(key, initialValues[key]);
				});

				return true; // Indicate successful data fetch
			} else if (!response.isLoading && response.isRelevant === false) {
				setError("Geen patiëntdata gevonden. Weet je zeker dat de patiëntenkaart open stond?");
			} else if (!response.isLoading && !hasPatientData(response.patientData)) {
				setError("Geen patiëntdata gevonden");
			}
			return false; // Indicate unsuccessful data fetch
		} catch (error) {
			console.error("Error fetching integration data:", error);
			toast.error("Fout bij het ophalen van de patiëntgegevens", { position: "bottom-center" });
			return false; // Indicate unsuccessful data fetch
		} finally {
			setIsLoading(false);
			setIsLoadingIntegrationResult(false);
		}
	}, [createPatient, setPrefilledValues, setFoundPhoneNumbers, setIsLoadingIntegrationResult, setFieldValue]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const documentId = queryParams.get("id");
		const key = queryParams.get("key");

		if (documentId && key && !initialData) {
			let attempts = 0;
			const maxAttempts = 10; 
			const pollInterval = 500;
			const initialDelay = 2000; // 2 seconds initial delay
			const errorTimeout = 5000; // 5 seconds before showing error

			const pollData = async () => {
				try {
					const success = await fetchIntegrationData(documentId, key);
					if (success) {
						return; // Exit polling if data is successfully fetched
					}

					attempts++;
					if (attempts < maxAttempts) {
						setTimeout(pollData, pollInterval);
					}
				} catch (error) {
					console.error("Error during polling:", error);
				}
			};

			// Set initial delay before starting to poll
			setTimeout(() => {
				pollData();

			}, initialDelay);
		}
	}, [location.search, fetchIntegrationData, initialData]);


	if (!isVisible) {
		return null;
	}

	if (isLoading) {
		return <LoadingSkeleton />;
	}

	if (error) {
		return <ErrorMessage message={error} />;
	}

	return (
		<div className="mb-6">
			<PatientInfoCard initialData={initialData} values={values} phoneNumbers={phoneNumbers} setStep={setStep} />
			{phoneNumbers.length > 1 && (
				<ChoosePhoneNumber
					phoneNumbers={phoneNumbers}
					onChange={(value) => setFieldValue("phoneNumber", value)}
				/>
			)}
		</div>
	);
};

const LoadingSkeleton = () => (
	<div className="mb-6">
		<div className="bg-slate-300 rounded-xl p-4 flex justify-between w-fit">
			<div className="pr-8 space-y-2">
				<SkeletonLoadingItem width="w-40" height="h-6" color="slate-500" />
				<SkeletonLoadingItem width="w-16" height="h-4" color="slate-400" />
				<p className="flex items-center">
					<PhoneIcon className="size-4 mr-2 text-slate-700" />
					<SkeletonLoadingItem width="w-20" height="h-4" color="slate-400" />
				</p>
			</div>
		</div>
	</div>
);

const ErrorMessage = ({ message }) => (
	<div className="bg-orange-50 border-orange-100 border rounded-lg px-4 py-3 text-sm h-fit">
		<p className="text-orange-900">{message}</p>
	</div>
);

const PatientInfoCard = ({ initialData, values, phoneNumbers, setStep }) => (
	<div className="bg-slate-300 rounded-xl p-4 flex justify-between w-fit">
		<div className="pr-8">
			<p className="text-lg font-semibold">
				{values.firstName || initialData.firstName} {values.lastName || initialData.lastName}
			</p>
			<p className="text-sm text-slate-700">{values.patientnummer || initialData.patientnummer}</p>
			{phoneNumbers.length === 1 && (
				<p className="flex items-center pt-2">
					<PhoneIcon className="size-4 mr-2 text-slate-700" />
					<FormattedPhoneNumber phoneNumber={values.phoneNumber || initialData.phoneNumber} />
				</p>
			)}
		</div>
		<div>
			<Tooltip content="Gegevens wijzigen" id="edit-patient">
				<button onClick={() => setStep(1)}>
					<PencilSquareIcon className="size-4 mr-1 text-slate-500" />
				</button>
			</Tooltip>
		</div>
	</div>
);

export default PrefilledFromDesktopInfo;