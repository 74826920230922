import React, { Suspense, useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import { SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import mixpanel from "mixpanel-browser";
import OneSignal from "react-onesignal";
import * as Sentry from "@sentry/react";
import { useIntercom } from "react-use-intercom";

import posthog from "posthog-js";
import * as Frigade from "@frigade/react";

// Components
import Sidebar from "./shared/components/Navigation/Sidebar";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import ScrollToTop from "./shared/components/util/ScrollToTop";

// Pages for practices
import Dashboard from "./shared/pages/Dashboard";
import LoginPage from "./users/pages/LoginPage";
import SignUpPage from "./users/pages/SignupPage";
import SettingsPage from "./users/pages/SettingsPage";
import PracticeSettings from "./practice/pages/PracticeSettings";
import AllPatients from "./patients/pages/AllPatients";
import NewSlotPage from "./slots/pages/NewSlotPage";
import AllSlotsPage from "./slots/pages/AllSlotsPage";
import AllTasksPage from "./tasks/pages/AllTasksPage";
import StatisticsPage from "./practice/pages/StatisticsPage";
import EditPatientPage from "./patients/pages/EditPatientPage";
import SingleSlot from "./slots/pages/SingleSlot";
import ImportPatientsPage from "./patients/pages/ImportPatientsPage";
import PaymentSucceeded from "./billing/pages/PaymentSucceeded";
import GettingStarted from "./practice/pages/GettingStarted";
import SlotsArchivePage from "./slots/pages/SlotsArchivePage";
import DownloadDesktopApp from "./shared/pages/DownloadDesktopApp";
import GetHelp from "./shared/pages/GetHelp";
import ChooseOnboardingType from "./billing/pages/ChooseOnboardingType";
import DeletedPatientsOverview from "./patients/pages/DeletedPatientsOverview";
import ErroredPatients from "./patients/pages/ErroredPatients";
import RedirectOnboarding from "./users/components/onboarding/RedirectToOnboarding";
import NoActiveSubscription from "./billing/pages/NoActiveSubscription";

// Help pages
import PatientInfoSite from "./practice/pages/help/PatientInfoSite";
import PatientInfoEmail from "./practice/pages/help/PatientInfoEmail";
import PatientInfoEmailFooter from "./practice/pages/help/PatientInfoEmailFooter";
import SocialMediaPost from "./practice/pages/help/SocialMediaPost";

// Pages for patients
import ManageMySubscription from "./public/pages/ManageMySubscription";
import SnoozeInvitations from "./public/pages/SnoozeInvitations";
import Availability from "./public/pages/Availability";
import ClaimSlotPage from "./public/pages/ClaimSlotPage";
import SlotConfirmed from "./public/pages/SlotConfirmed";
import SlotAlreadyClaimedPage from "./public/pages/SlotAlreadyClaimed";
import DeletePatient from "./public/pages/DeletePatient";
import RequestAccount from "./billing/pages/RequestAccount";
import AuthenticateDesktopApp from "./users/pages/AuthenticateDesktopApp";

import NewPatientPage from "./patients/pages/NewPatientPage";

async function runOneSignal() {
	try {
		await OneSignal.init({
			appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
			allowLocalhostAsSecureOrigin: true,
		});
		OneSignal.Slidedown.promptPush();
	} catch (error) {
		console.error(error);
	}
}

function App() {
	const { boot } = useIntercom();
	const { user } = useUser();

	useEffect(() => {
		mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
			debug: false,
			track_pageview: true,
			persistence: "localStorage",
		});

		if (user) {
			const intercomUser = {
				user_id: user.id,
				email: user.primaryEmailAddress.emailAddress,
				name: `${user.firstName} ${user.lastName}`,
			};

			boot(intercomUser);

			runOneSignal();
			OneSignal.login(user.id);

			if (process.env.REACT_APP_STAGING !== "true") {
				Sentry.init({
					dsn: process.env.REACT_APP_SENTRY_DSN,
					// Performance Monitoring
					tracesSampleRate: 1.0, //  Capture 100% of the transactions
					// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
					tracePropagationTargets: [
						"localhost",
						/^https:\/\/api\.snelterecht\.nl\/api/,
					],
					replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
					replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
				});
				Sentry.setUser({
					id: user.id,
					email: user.primaryEmailAddress.emailAddress,
				});
			}

			mixpanel.identify(user.id);

			posthog.init("phc_A57mMbQ5HPzgqKM9EgLHO6bCDBFg5YUJNCrsnFWh0NR", {
				api_host: "https://eu.i.posthog.com",
				person_profiles: "identified_only",
				session_recording: {
					maskAllInputs: true,
					maskTextSelector: "*",
					maskTextFn: (text, element) => {
						if (element?.dataset["record"] === "true") {
							return text;
						}
						return "*".repeat(text.trim().length);
					},
				},
			});

			posthog.identify(user.id, {
				email: user.primaryEmailAddress.emailAddress,
				name: `${user.firstName} ${user.lastName}`,
			});
		}
	}, [user]);

	const routesWithSidebar = [
		<Route path="/" element={<Dashboard />} />,
		<Route path="/start" element={<GettingStarted />} />,
		<Route path="/help/patienten/site" element={<PatientInfoSite />} />,
		<Route path="/help/patienten/email" element={<PatientInfoEmail />} />,
		<Route
			path="/help/patienten/tekst"
			element={<PatientInfoEmailFooter />}
		/>,
		<Route path="/help/patienten/post" element={<SocialMediaPost />} />,
		<Route path="/help" element={<GetHelp />} />,
		<Route path="/patienten/importeren" element={<ImportPatientsPage />} />,
		<Route
			path="/patienten/verwijderd"
			element={<DeletedPatientsOverview />}
		/>,
		<Route path="/patienten/foutmeldingen" element={<ErroredPatients />} />,
		<Route path="/patienten/:patientId" element={<AllPatients />} />,

		<Route path="/gaten/" element={<AllSlotsPage />} />,
		<Route path="/gaten/archief" element={<SlotsArchivePage />} />,
		<Route path="/gaten/all" element={<Navigate to="/gaten/" />} />,
		<Route path="/gaten/:slotId" element={<SingleSlot />} />,
		<Route path="/taken/" element={<AllTasksPage />} />,
		<Route path="/beheer" element={<PracticeSettings />} />,
		<Route path="/overzicht" element={<StatisticsPage />} />,
		<Route path="/integratie" element={<DownloadDesktopApp />} />,
		<Route path="/c/claimed" element={<SlotAlreadyClaimedPage />} />,
		<Route path="/c/bevestiging/:slotId" element={<SlotConfirmed />} />,
		<Route path="/c/verwijder/:patientId" element={<DeletePatient />} />,
		<Route path="/c/pt/:patientId" element={<ManageMySubscription />} />,
		<Route
			path="/c/beschikbaarheid/:patientId"
			element={<Availability />}
		/>,
		<Route path="/c/snooze/:patientId" element={<SnoozeInvitations />} />,
		<Route path="/c/:slotId/:patientId" element={<ClaimSlotPage />} />,
		<Route path="/betaling/succes" element={<PaymentSucceeded />} />,
		<Route path="*" element={<Navigate to="/" />} />,
	];

	const publicRoutes = [
		// <Route path="/c/claimed" element={<SlotAlreadyClaimedPage />} />,
		// <Route path="/c/bevestiging/:slotId" element={<SlotConfirmed />} />,
		// <Route path="/c/:slotId/:patientId" element={<ClaimSlotPage />} />,
	];

	const routesWithoutSidebar = [
		<Route path="/gaten/nieuw/" element={<NewSlotPage />} />,
		<Route path="/instellingen/*" element={<SettingsPage />} />,
		<Route path="/aanvragen" element={<RequestAccount />} />,
		<Route path="/redirect/onboarding" element={<RedirectOnboarding />} />,
		<Route path="/patienten/nieuw/" element={<NewPatientPage />} />,
		<Route
			path="/patienten/bewerken/:patientId"
			element={<EditPatientPage />}
		/>,
		<Route path="/auth/desktop" element={<AuthenticateDesktopApp />} />,
	];

	const routesSignedout = [
		<Route path="/wachtwoord" element={<SignUpPage />} />,
		<Route path="/inloggen" element={<LoginPage />} />,
		<Route path="/intake" element={<ChooseOnboardingType />} />,
		<Route path="/c/claimed" element={<SlotAlreadyClaimedPage />} />,
		<Route path="/c/bevestiging/:slotId" element={<SlotConfirmed />} />,
		<Route path="/c/pt/:patientId" element={<ManageMySubscription />} />,
		<Route path="/c/:slotId/:patientId" element={<ClaimSlotPage />} />,
		<Route path="/c/verwijder/:patientId" element={<DeletePatient />} />,
		<Route path="/c/snooze/:patientId" element={<SnoozeInvitations />} />,
		<Route
			path="/c/beschikbaarheid/:patientId"
			element={<Availability />}
		/>,
		<Route path="/betaling/succes" element={<PaymentSucceeded />} />,
		<Route path="/aanvragen" element={<RequestAccount />} />,
		<Route path="*" element={<Navigate to="/inloggen" />} />,
	];

	const FRIGADE_THEME_OVERRIDES = {
		colors: {
			primary: {
				border: "#B692F6",
				surface: "#7634DE",
				hover: {
					surface: "#7F56D9",
				},
			},
		},
		radii: {
			md: "11px",
			lg: "var(--fr-radii-lg)",
			round: "var(--fr-radii-round)",
		},
		shadows: { md: "var(--fr-shadows-md)" },
	};

	return (
		<Router>
			<SignedIn>
				{user && (
					<>
						{user.publicMetadata.activeSubscription === false ? (
							<NoActiveSubscription />
						) : (
							<>
								<Frigade.Provider
									apiKey={
										process.env.REACT_APP_FRIGADE_API_KEY
									}
									userId={user.id}
									theme={FRIGADE_THEME_OVERRIDES}
									userProperties={{
										firstName: user.firstName,
										lastName: user.lastName,
										email: user.primaryEmailAddress
											.emailAddress,
									}}
								>
									<Suspense fallback={<LoadingSpinner />}>
										<ScrollToTop />
										<Routes>
											{routesWithoutSidebar}
											{routesWithSidebar.map(
												(route, idx) => (
													<Route
														key={idx}
														path={route.props.path}
														element={
															<Sidebar>
																{
																	route.props
																		.element
																}
															</Sidebar>
														}
													/>
												),
											)}
										</Routes>
									</Suspense>
								</Frigade.Provider>
							</>
						)}
					</>
				)}
			</SignedIn>
			<SignedOut>
				<Routes>{routesSignedout}</Routes>
			</SignedOut>
			<Routes>{publicRoutes}</Routes>
		</Router>
	);
}

export default App;
