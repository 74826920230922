import React, { useState, useEffect } from "react";

import { useUser } from "@clerk/clerk-react";

import {
	CalendarDaysIcon,
	CalendarIcon,
	ClockIcon,
	UserPlusIcon,
	UsersIcon,
	HomeIcon,
	PhoneArrowUpRightIcon,
} from "@heroicons/react/20/solid";

import Heading from "../components/UIElements/Heading";
import useFetch from "../hooks/useFetch";

import GettingStarted from "../../practice/pages/GettingStarted";

import UpgradeToYearly from "../../billing/components/UpgradeToYearly";
import MiniSlotList from "../../slots/components/MiniSlotList";
import Button from "../components/FormElements/Button";
import SubHeading from "../components/UIElements/SubHeading";
import { ShieldCheckIcon } from "@heroicons/react/16/solid";
import SkeletonLoadingItem from "../components/UIElements/SkeletonLoadingItem";
import AnimateIn from "../components/Animations/AnimateIn";

const Dashboard = () => {
	const { user } = useUser();
	const [stats, setStats] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [hasActiveSlots, setHasActiveSlots] = useState(true);

	const fetchStats = useFetch();

	useEffect(() => {
		const getNotifications = async () => {
			const response = await fetchStats(
				`/api/practice/user/${user.id}/stats`,
			);
			setStats(response);
			setIsLoading(false);
		};

		getNotifications();
	}, []);

	if (user.unsafeMetadata.hasCompletedOnboarding !== true) {
		return <GettingStarted />;
	}

	return (
		<>
			<div className="border-b-2 border-gray-200">
				<DashboardContainer>
					{hasActiveSlots && (
						<div className="flex justify-between items-center mb-10">
							<div>
								<div className="flex gap-2 items-center mb-3">
									<HomeIcon className="size-4 text-slate-400" />
									<h3 className="text-sm font-semibold text-slate-500 uppercase">
										Dashboard
									</h3>
								</div>
								<Heading>Vrijgekomen plekken</Heading>
							</div>
							<DashboardButtons />
						</div>
					)}

					<MiniSlotList setHasActiveSlots={setHasActiveSlots} />
				</DashboardContainer>
			</div>
			<div>
				<DashboardContainer>
					<div className="flex gap-x-4 items-baseline ">
						<Heading>Statistieken</Heading>

						<TotalSlotsFilledBadge
							amountOfSlotsFilled={stats.totalSlotsFilled}
						/>
					</div>
					<div className="mt-4 mb-10">
						<SubHeading className="mb-3">
							Afgelopen 30 dagen
						</SubHeading>
						<div className="grid grid-cols-4 gap-4">
							<DashboardCard
								icon={CalendarDaysIcon}
								value={stats.pastMonth?.slotsFilled}
								label={
									stats.pastMonth?.slotsFilled === 1
										? "lege plek"
										: "lege plekken"
								}
								subtitle="gevuld"
								isLoading={isLoading}
							/>
							<DashboardCard
								icon={PhoneArrowUpRightIcon}
								value={stats.pastMonth?.callsSaved}
								label="telefoontjes"
								subtitle="bespaard"
								isLoading={isLoading}
							/>
							<DashboardCard
								icon={ClockIcon}
								value={stats.pastMonth?.averageResponseTime}
								label="minuten"
								subtitle="reactietijd"
								isLoading={isLoading}
							/>
						</div>
					</div>

					<div className="mt-3">
						<SubHeading className="mb-4">
							Afgelopen 7 dagen
						</SubHeading>
						<div className="grid grid-cols-4 gap-4">
							<DashboardCard
								icon={UserPlusIcon}
								value={stats.pastWeek?.patientsAdded}
								label="patiënten"
								subtitle="toegevoegd"
								isLoading={isLoading}
							/>
						</div>
					</div>

					<div className="mt-16">
						<UpgradeToYearly includeIgnoreButton={true} />
					</div>
				</DashboardContainer>
			</div>
		</>
	);
};

const DashboardContainer = ({ children }) => {
	return (
		<div className={"bg-[#F8FAFC] bg-opacity-40 py-14"}>
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 lg:pr-20">
				{children}
			</div>
		</div>
	);
};

const DashboardButtons = () => {
	return (
		<div className="flex gap-3">
			<Button ghost iconPrefix={UserPlusIcon} to="/patienten/nieuw">
				Patiënt toevoegen
			</Button>
			<Button iconPrefix={CalendarIcon} to="/gaten/nieuw">
				Lege plek melden
			</Button>
		</div>
	);
};

const TotalSlotsFilledBadge = ({ amountOfSlotsFilled }) => {
	return (
		<div
			data-record="true"
			className="bg-[#E3F4E7] bg-opacity-70 text-[#2AAF2A] border-[#2AAF2A] border-[0.8px] border-opacity-70 px-2 py-1 rounded-lg flex items-center gap-x-1 text-sm font-medium"
		>
			<ShieldCheckIcon className="w-4 h-4" />
			{amountOfSlotsFilled} plekken gevuld in totaal
		</div>
	);
};

const DashboardCard = ({ icon: Icon, value, label, subtitle, isLoading }) => {
	if (value === "0" || value === 0 || value === "00:00") {
		return null;
	}

	return (
		<div className="bg-white p-6 rounded-3xl border-slate-200 border shadow-sm flex items-start gap-x-2 justify-between">
			<div>
				<p className="font-bold text-xl flex items-center gap-x-1.5">
					{isLoading ? (
						<SkeletonLoadingItem width="w-8" height="h-5" />
					) : (
						<span data-record="true">{value}</span>
					)}
					<span className="font-normal" data-record="true">
						{label}
					</span>
				</p>

				<p className="text-slate-500" data-record="true">
					{subtitle}
				</p>
			</div>
			<div className="self-end">
				<Icon className="size-6 text-brand-intense" />
			</div>
		</div>
	);
};

export default Dashboard;
