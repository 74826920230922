import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import useFetch from "../../../shared/hooks/useFetch";
import Container from "../../../shared/components/UIElements/Container";
import Heading from "../../../shared/components/UIElements/Heading";
import MiniBackButton from "../../../shared/components/FormElements/MiniBackButton";

import CheckList from "../../../shared/components/UIElements/CheckList";
import MiniCopyButton from "../../../shared/components/FormElements/MiniCopyButton";

import socialPosts from "../../../assets/SocialMediaPosts.png";
import AnimateIn from "../../../shared/components/Animations/AnimateIn";

const SocialMediaPost = () => {
	const [practice, setPractice] = useState("");
	const [email, setEmail] = useState("");

	const { user } = useUser();
	const fetchPractice = useFetch();

	useEffect(() => {
		const getPractice = async () => {
			const practiceReq = await fetchPractice(
				`/api/practice/user/${user.id}`,
				"GET",
			);
			setPractice(practiceReq);
		};

		getPractice();
	}, [fetchPractice, user]);

	const encodedPractice = encodeURIComponent(practice.name);
	const link = `https://snelterecht.nl/patienten/?pt=${encodedPractice}`;

	const generateEmailContent = (practice) => {
		return `💬 Bij ${practice.name} werken we vanaf nu met Snelterecht, zodat onze patiënten altijd snel terecht kunnen bij één van onze mondzorgprofessionals! 
		
		Mocht je sneller terecht willen dan je oorspronkelijke afspraak, dan kunnen wij je op onze wachtlijst zetten. Zodra er op korte termijn een plekje vrijkomt, krijg je van ons bericht via WhatsApp.
		
		Zo zorgen we voor maximale efficiëntie en minimale wachttijden.
		
		Handig toch? 😷
		
		#snelterecht`;
	};

	useEffect(() => {
		if (practice) {
			setEmail(generateEmailContent(practice));
		}
	}, [practice]);

	return (
		<Container>
			<div className="flex gap-x-12">
				<div className="w-2/5">
					<MiniBackButton to="/help" />
					<Heading>Social media post</Heading>
					<p className="text-gray-600 mt-2 mb-6">
						Voor patiënten is Snelterecht een mooie service, dus je
						kunt het ook posten op social media!
					</p>

					<CheckList items={["Facebook, Instagram of LinkedIn"]} />
					<div className="mt-10">
						{practice && (
							<p className="text-slate-700 bg-white p-4 rounded-xl border-slate-300 border shadow-sm">
								{generateEmailContent(practice)
									.split("\n")
									.map((line, index) => (
										<React.Fragment key={index}>
											{line}
											<br />
										</React.Fragment>
									))}
							</p>
						)}
					</div>
					<MiniCopyButton
						text={email}
						includeText={true}
						includeLabel={true}
						label="Kopieer post"
						classNames="mt-4 scale-110"
					/>
				</div>
				<div className="w-3/5">
					<AnimateIn>
						<img src={socialPosts} alt="Social Media Posts" />
					</AnimateIn>
				</div>
			</div>
		</Container>
	);
};

export default SocialMediaPost;
