import React, { useEffect, useState, useCallback } from "react";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	UsersIcon,
} from "@heroicons/react/16/solid";
import { CalendarIcon, ClockIcon, UserIcon } from "@heroicons/react/24/outline";
import useFetch from "../../../shared/hooks/useFetch";
import Button from "../../../shared/components/FormElements/Button";
import FormattedDate from "../../../shared/components/util/FormattedDate";

import SelectPatientsTable from "../../../patients/components/SelectPatientsTable";
import PatientFilters from "./PatientFilters";
import AnimateIn from "../../../shared/components/Animations/AnimateIn";

const NewSlotPatients = ({
	slotID,
	onChosenPatients,
	onBack,
	selectedPatients,
	setSelectedPatients,
}) => {
	const [patients, setPatients] = useState([]);
	const [notAvailablePatients, setNotAvailablePatients] = useState([]);
	const [slot, setSlot] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [filters, setFilters] = useState({});
	const [availableTreatments, setAvailableTreatments] = useState([]);
	const [showTreatmentFilter, setShowTreatmentFilter] = useState(false);

	const fetchPatients = useFetch();

	const getPatients = useCallback(async () => {
		setIsLoading(true);
		const queryParams = new URLSearchParams(filters).toString();
		const response = await fetchPatients(
			`/api/slots/${slotID}/patients?${queryParams}`,
		);
		setPatients(response.eligiblePatients);
		setNotAvailablePatients(response.notAvailablePatients);
		setSlot(response.slot);
		setIsLoading(false);

		setShowTreatmentFilter(
			response.slot.behandelaar?.occupation?.includeTreatments || false,
		);

		return response;
	}, [slotID, filters, fetchPatients]);

	useEffect(() => {
		getPatients().then((response) => {
			if (selectedPatients.length === 0) {
				setSelectedPatients(
					response.eligiblePatients.map((p) => p._id),
				);
			} else {
				setSelectedPatients((prevSelected) =>
					prevSelected.filter((id) =>
						response.eligiblePatients.some((p) => p._id === id),
					),
				);
			}

			const allTreatments = [
				...response.eligiblePatients,
				...response.notAvailablePatients,
			]
				.map((patient) => patient.appointment?.treatmentType)
				.filter((treatment) => treatment != null)
				.filter(
					(treatment, index, self) =>
						index ===
						self.findIndex((t) => t._id === treatment._id),
				);
			setAvailableTreatments(allTreatments);
		});
	}, [getPatients, setSelectedPatients]);

	const choosePatients = async () => {
		await fetchPatients(`/api/slots/${slotID}/patients`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ patients: selectedPatients }),
		});
		onChosenPatients(selectedPatients);
	};

	const handleFilterChange = (newFilters) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			...newFilters,
		}));
	};

	return (
		<>
			<AnimateIn>
				<div className="bg-white rounded-2xl border border-slate-200 px-10 py-12 mb-14">
					<h3 className="text-xl leading-6 font-bold text-gray-900 flex items-center gap-2">
						<UsersIcon className="size-5 text-brand-intense" />
						Welke patiënten wil je uitnodigen?
						<span className="font-medium text-gray-500 text-sm">
							({patients?.length} komen in aanmerking)
						</span>
					</h3>
					<p className="mt-1 text-sm text-gray-500">
						Deze patiënten komen in aanmerking voor de lege plek in
						de agenda, wie wil je uitnodigen?
					</p>
					{slot.behandelaar && (
						<div className="flex mt-5 space-x-3 text-sm text-gray-500">
							<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200">
								<UserIcon className="h-5 w-5 text-slate-600 mr-3" />
								<p className="text-slate-700">
									{slot.behandelaar.firstName}{" "}
									{slot.behandelaar.lastName}
								</p>
							</p>
							<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200 text-slate-700">
								<CalendarIcon className="h-5 w-5 text-slate-600 mr-3" />
								<FormattedDate date={slot.date} />
							</p>
							<p className="flex items-center bg-slate-50 py-2 px-3 rounded-full border border-slate-200">
								<ClockIcon className="h-5 w-5 text-slate-600 mr-3" />
								<p className="text-slate-700">
									{slot.startTime} - {slot.endTime} uur (
									{slot.duration} min)
								</p>
							</p>
						</div>
					)}

					<div
						className={
							availableTreatments.length > 0
								? "flex gap-10"
								: "flex"
						}
					>
						<div className="">
							<PatientFilters
								slotId={slotID}
								onFilterChange={handleFilterChange}
								availableTreatments={availableTreatments}
								showTreatmentFilter={showTreatmentFilter}
							/>
						</div>
						<div className="w-full">
							<SelectPatientsTable
								patients={patients}
								notAvailablePatients={notAvailablePatients}
								isLoading={isLoading}
								selectedPatients={selectedPatients}
								setSelectedPatients={setSelectedPatients}
								slotDuration={slot.duration}
							/>
						</div>
					</div>
				</div>
			</AnimateIn>
			<div className="shadow-2xl fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 flex gap-2 justify-start">
					<Button onClick={onBack} ghost iconPrefix={ArrowLeftIcon}>
						Terug
					</Button>

					<Button
						onClick={choosePatients}
						disabled={selectedPatients.length === 0}
						iconSuffix={ArrowRightIcon}
					>
						Naar benaderplan{" "}
						<span className="font-normal pl-1 opacity-80">
							({selectedPatients.length} patiënten)
						</span>
					</Button>
				</div>
			</div>
		</>
	);
};

export default NewSlotPatients;
