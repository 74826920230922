import { useUser, SignOutButton } from "@clerk/clerk-react";

import { Link } from "react-router-dom";
import {
	ArrowLeftIcon,
	ArrowTopRightOnSquareIcon,
} from "@heroicons/react/16/solid";
import { ArrowLeftStartOnRectangleIcon } from "@heroicons/react/20/solid";
import CopyText from "../../../shared/components/FormElements/CopyText";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const SettingsSidebar = ({
	navItems,
	activeItem,
	setActiveItem,
	children,
	...props
}) => {
	const { user } = useUser();

	return (
		<>
			<div>
				<div className="flex md:w-80 md:flex-col md:fixed md:inset-y-0 bg-slate-800 slide-in">
					<div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 ">
						<div className="flex-1 flex flex-col pt-16 pb-4 overflow-y-auto">
							<div className="px-10 pb-10">
								<Link
									to={"/"}
									className="flex gap-1 items-center bg-slate-700 text-slate-200 rounded-full py-1 px-3 text-sm mb-4 hover:bg-opacity-70 transition-all duration-200 w-fit"
								>
									<ArrowLeftIcon className="w-3.5 h-3.5" />
									Terug
								</Link>
								<h1 className="text-2xl font-bold text-white">
									Instellingen en voorkeuren
								</h1>
							</div>
							<nav className="mt-5 px-6 space-y-8 flex flex-col ">
								{navItems.map((section) =>
									section.hideWhenNoCustomerId &&
									!user.publicMetadata.customerId ? null : (
										<div key={section.title}>
											{section.title && (
												<h3 className="text-sm font-semibold text-slate-400 uppercase tracking-wide pl-3 pb-2 pt-4">
													{section.title}
												</h3>
											)}
											<div className="space-y-1">
												{section.items.map((item) =>
													item.hide ? null : (
														<Link
															to={item.href}
															onClick={() =>
																item.component &&
																setActiveItem(
																	item
																)
															}
															className={classNames(
																activeItem.name ===
																	item.name
																	? "font-semibold bg-slate-700 text-white"
																	: " font-medium text-slate-200 hover:bg-slate-700",
																"text-left py-2 px-3 rounded-lg flex items-center transition-colors duration-400",
																item.classNames
															)}
															target={
																item.external
																	? "_blank"
																	: ""
															}
															key={item.name}
														>
															<item.icon
																className={classNames(
																	activeItem.name ===
																		item.name 
																		? "text-[#9c72ff]"
																		: "text-slate-200 opacity-80 group-hover:text-slate-500",
																	"mr-3 flex-shrink-0 size-5"
																)}
																aria-hidden="true"
															/>
															{item.name}
															{item.external && (
																<ArrowTopRightOnSquareIcon className="w-4 ml-2 opacity-60" />
															)}
														</Link>
													)
												)}
											</div>
										</div>
									)
								)}
							</nav>
						</div>
						<div className="px-10 pb-10 text-center mx-auto">
							<SignOutButton>
								<div className="flex items-center cursor-pointer text-sm text-red-300 hover:text-red-400 transition-all duration-150">
									<ArrowLeftStartOnRectangleIcon className="size-5 mr-2" />
									Uitloggen
								</div>
							</SignOutButton>
						</div>
					</div>
				</div>
				<div className="md:pl-80 flex flex-col flex-1">
					<main className="flex-1">{children}</main>
					{process.env.REACT_APP_STAGING === "true" && (
						<div className="pb-8 text-center text-sm text-gray-500">
							User ID:{" "}
							<div className="inline-block w-fit">
								<CopyText text={user.id} />
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default SettingsSidebar;
