import React from "react";
import { Formik, Form } from "formik";
import CheckboxGroup from "../../../shared/components/FormElements/CheckboxGroup";

const PatientFilters = ({
	slotId,
	onFilterChange,
	availableTreatments,
	showTreatmentFilter,
}) => {
	if (
		!showTreatmentFilter ||
		!availableTreatments ||
		availableTreatments.length === 0
	) {
		return null;
	}

	const initialValues = {
		treatments: availableTreatments.map((t) => t._id),
	};

	const handleSubmit = (values, { setSubmitting }) => {
		onFilterChange(values);
		setSubmitting(false);
	};

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			{({ isSubmitting, setFieldValue, values }) => (
				<Form>
					<p className="uppercase text-xs font-bold text-slate-500 -mb-5 mt-10">
						Filters
					</p>
					<CheckboxGroup
						label="Behandelingen"
						name="treatments"
						options={availableTreatments.map((t) => ({
							label: t.name,
							value: t._id,
						}))}
						onChange={(selectedTreatments) => {
							setFieldValue("treatments", selectedTreatments);
							// Immediately trigger filter change
							onFilterChange({ treatments: selectedTreatments });
						}}
					/>
				</Form>
			)}
		</Formik>
	);
};

export default PatientFilters;
