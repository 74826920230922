import React, { useState } from "react";
import {
	CalendarIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from "@heroicons/react/16/solid";
import DatePicker from "../../../shared/components/FormElements/DatePicker";
import Input from "../../../shared/components/FormElements/Input";
import SelectBehandelaar from "../../../practice/components/Behandelaren/SelectBehandelaar";
import Checkbox from "../../../shared/components/FormElements/Checkbox";
import Select from "../../../shared/components/FormElements/Select";
import Textarea from "../../../shared/components/FormElements/Textarea";
import PrefilledFromDesktopInfo from "./PrefilledFromDesktopInfo";

import FieldSuggestions from "../../../shared/components/FormElements/FieldSuggestions";
import classNames from "classnames";
import TreatmentSuggestions from "./TreatmentSuggestions";
import MultiplePhoneInputs from "../../../shared/components/FormElements/MultiplePhoneInputs";

const PatientAdvancedInfo = ({
	values,
	setFieldValue,
	fromDesktopApp,
	availableTreatments,
	treatments,
	setBehandelaarTreatments,
	isLoadingIntegrationResult,
	foundPhoneNumbers,
}) => {
	const [showAdvanced, setShowAdvanced] = useState(false);
	const [selectedOccupation, setSelectedOccupation] = useState(null);

	return (
		<>
			<h3 className="text-xl leading-6 font-bold text-gray-900 flex items-center gap-2">
				<CalendarIcon className="size-5 text-brand-intense" />
				Afspraakgegevens
			</h3>

			{(!foundPhoneNumbers || foundPhoneNumbers?.length === 0) &&
				!isLoadingIntegrationResult && (
					<MultiplePhoneInputs
						amountOfPhones={
							values.phoneNumber3
								? 3
								: values.phoneNumber2
									? 2
									: 1
						}
						maxPhones={3}
					/>
				)}

			<DatePicker
				label="Datum van de geplande afspraak"
				name="unformattedDate"
				tooltip="Laat leeg als de patiënt nog geen afspraak heeft staan"
			/>
			<Input
				label="Duur van de behandeling"
				name="behandelingDuur"
				type="number"
				suffix="minuten"
				width="1/2"
			/>

			<FieldSuggestions
				suggestions={[
					{ label: "30 min", value: 30 },
					{ label: "40 min", value: 40 },
					{ label: "45 min", value: 45 },
				]}
				fieldName="behandelingDuur"
				isVisible={!values.behandelingDuur}
			/>

			<SelectBehandelaar
				label="Wie is de behandelaar voor deze afspraak?"
				name="behandelaar"
				includeOccupations={false}
				setSelectedOccupation={setSelectedOccupation}
				setBehandelaarTreatments={setBehandelaarTreatments}
				value={values.behandelaar}
				defaultValue={values.behandelaar}
			/>
			<div
				className={classNames(
					"text-sm transition-all duration-700 ease-in-out",
					{
						"opacity-100 max-h-20 pb-2":
							values.behandelaar && selectedOccupation,
						"opacity-0 max-h-0 pb-0 pt-0 pointer-events-none -mt-3":
							!values.behandelaar && !selectedOccupation,
					},
				)}
			>
				<Checkbox
					label={`Mogen andere ${selectedOccupation && selectedOccupation?.plural} deze patiënt ook behandelen?`}
					name="otherBehandelaars"
				/>
			</div>
			{selectedOccupation &&
				selectedOccupation._id === "659fcad31bfb44314301ca81" && (
					<>
						<Select
							label="Soort behandeling"
							name="treatment"
							options={
								availableTreatments.length > 0
									? availableTreatments
									: treatments
							}
							width="1/2"
							required
						/>
						<TreatmentSuggestions
							treatments={treatments}
							duration={values.behandelingDuur}
							treatment={values.treatment}
						/>
					</>
				)}
			<Checkbox
				label="Deze patiënt is spoed"
				description="Geef voorrang bij de uitnodigingen."
				name="priority"
			/>
			<div className="pt-10 w-full">
				<button
					onClick={(e) => {
						e.preventDefault();
						setShowAdvanced(!showAdvanced);
					}}
					className="text-sm uppercase font-semibold text-gray-500 flex items-center"
				>
					Geavanceerd{" "}
					<ChevronUpIcon
						className={`h-4 w-4 ml-1 transition-transform duration-300 ${
							showAdvanced ? "rotate-180" : ""
						}`}
					/>
				</button>

				<div
					className={classNames(
						"-mt-2 mb-6 transition-all duration-300 ease-in-out",
						{
							"max-h-0 opacity-0 overflow-hidden": !showAdvanced,
							"max-h-96 opacity-100": showAdvanced,
						},
					)}
				>
					<DatePicker
						label="Oproepen vanaf"
						name="unformattedInviteFrom"
						optional
					/>
					<Textarea
						label="Opmerkingen"
						name="notes"
						rows={3}
						optional
					/>
					<p className="text-sm mt-2 mb-4 text-gray-500">
						Dit krijgt de patiënt niet te zien.
					</p>
				</div>
			</div>
		</>
	);
};

export default PatientAdvancedInfo;
